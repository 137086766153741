import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCJxk8Of7RqivJo5BJ9keK7ZIfnI4EOqd4",
    authDomain: "appspectrum-technology.firebaseapp.com",
    projectId: "appspectrum-technology",
    storageBucket: "appspectrum-technology.appspot.com",
    messagingSenderId: "929016778489",
    appId: "1:929016778489:web:1557317f908af7a7b25c28",
    measurementId: "G-R2W5ZEKDFH"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
