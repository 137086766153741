import React, {useState, Fragment, useEffect} from "react"
import {Link} from "gatsby"
import {Dialog, Transition} from "@headlessui/react";
import {useLenis} from "@studio-freight/react-lenis";
import {getAnalytics, isSupported, logEvent} from "firebase/analytics";
import {app} from "../firebase";
import {useLocation} from "@reach/router";
import {isLoadingIndicatorEnabled} from "../../.cache/_this_is_virtual_fs_path_/$virtual/loading-indicator";



const Header = () => {

    const [open, setOpen] = useState(false);
    const lenis = useLenis();

    const location = useLocation()

    const logEmailAction = async () => {
        const isSup = await isSupported()
        if (isSup) {
            const analytics = getAnalytics(app);
            logEvent(analytics, 'email_click');
        }
        console.log("hello")
    }

    const isHomepage = location.pathname === "/";
    const isLearning = location.pathname === "/learning/";
    const isScrollToServices = location.hash.includes("#services");



    useEffect(() => {
        if (isScrollToServices && lenis?.scrollTo) {
            setTimeout(() => {
                lenis.scrollTo("#services");
            }, 1000); // Adjust the delay as needed
        }
    }, [location.hash && lenis]);



    return (
        <>
            <header id={'global-header'} dir={'ltr'} aria-label={'Global'} className={'sticky top-0 bg-zinc-950 h-20 z-50 rounded-b-[32px]'}>
                <div className={'custom-container mx-auto flex items-center h-full'}>
                    <div className={"flex items-center text-white text-lg font-medium"}>
                        <img alt={"AppSpectrum Technology Logo"} src={"https://ik.imagekit.io/kocdej7gl/ast_logo_new.png"} className={'h-12'} />
                        <div className={"ml-4 leading-tight"}>
                            AppSpectrum <br/> Technology
                        </div>
                    </div>
                    <div className={'ml-auto space-x-8 hidden lg:flex'}>
                        {/* Render as buttons if on homepage, otherwise render as links */}
                        {isHomepage ? (
                            <>
                                <button onClick={() => {
                                    lenis.scrollTo("#creations")
                                }} className={'group nav-link'}>
                                    <div className={'z-0 pseudo-nav-bg'}/>
                                    Creations
                                </button>
                                <button onClick={() => {
                                    lenis.scrollTo("#services")
                                }} className={'group nav-link'}>
                                    <div className={'z-0 pseudo-nav-bg'}/>
                                    Services
                                </button>
                                <Link to={"/learning"} className={"group nav-link"}>
                                    <div className={'z-0 pseudo-nav-bg'}/>
                                    Learning
                                </Link>
                                <button onClick={() => {
                                    lenis.scrollTo("#hire-us");
                                    logEmailAction()
                                }} className={'group text-indigo-400 hover:text-black nav-link !font-medium'}>
                                    <div className={'z-0 pseudo-nav-bg pseudo-nav-bg-primary'}/>
                                    <div className={'z-10 relative'}>
                                        Hire Us
                                    </div>
                                </button>
                            </>
                        ) : (
                            <>
                                <Link to={"/#creations"} className={"group nav-link"}>
                                    <div className={'z-0 pseudo-nav-bg'}/>
                                    Creations
                                </Link>
                                <Link to={"/#services"} className={"group nav-link"}>
                                    <div className={'z-0 pseudo-nav-bg'}/>
                                    Services
                                </Link>
                                <Link to={"/learning"} className={`group nav-link`}>
                                    <div className={'z-0 pseudo-nav-bg'}/>
                                    Learning
                                </Link>

                                <Link to={"/#hire-us"}  className={`group text-indigo-400 hover:text-black nav-link !font-medium`}>
                                    <div className={'z-0 pseudo-nav-bg pseudo-nav-bg-primary'}/>
                                    <div className={'z-10 relative'}>
                                        Hire Us
                                    </div>
                                </Link>
                            </>
                        )}

                    </div>
                    <button onClick={() => {
                        setOpen(true)
                    }} className={'ml-auto flex flex-col items-center lg:hidden'}>
                        <div className={'w-8 h-[2px] bg-white mb-3 mt-2'}/>
                        <div className={'w-8 h-[2px] bg-white'}/>
                    </button>
                </div>
            </header>
            <Transition show={open} as={Fragment}>
                <Dialog as="div" className="z-50 relative inset-0 overflow-hidden" onClose={() => {
                    setOpen(false)
                }}>
                    <div className={"absolute inset-0 overflow-hidden"}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed h-full inset-0 transition-opacity bg-zinc-800/40"/>
                        </Transition.Child>
                        <div className="fixed h-0 inset-y-0 left-0 right-0 w-full flex items-start">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-[cubic-bezier(0.33, 0, 0, 1)] duration-300"
                                enterFrom="transform -translate-y-full"
                                enterTo="transform-none"
                                leave="transform transition ease-[cubic-bezier(0.33, 0, 0, 1)] duration-300"
                                leaveFrom="transform-none"
                                leaveTo="transform -translate-y-full"
                            >
                                <div className={`h-auto w-screen `} >
                                    <div
                                        className={`shadow-2xl shadow-black/40 bg-zinc-950 backdrop-saturate-200 h-auto flex flex-col overflow-y-scroll rounded-b-[32px] border-zinc-700 overflow-hidden`}
                                        style={{
                                            paddingBottom: "env(safe-area-inset-bottom)"
                                        }}
                                    >
                                        <div className={'p-6'}>
                                            <div className={"flex items-center text-white text-lg font-medium"}>
                                                <img alt={"AppSpectrum Technology Logo"} src={"https://ik.imagekit.io/kocdej7gl/ast_logo_new.png"} className={'h-12'} />
                                                <div className={"ml-4 leading-tight"}>
                                                    AppSpectrum <br/> Technology
                                                </div>
                                            </div>
                                            <div className={'space-y-8 flex flex-col mt-8'}>
                                                { isHomepage ? (
                                                    <>
                                                        <button onClick={() => {
                                                            lenis.scrollTo("#creations");
                                                            setOpen(false)
                                                        }} className={'text-4xl font-medium group nav-link'}>
                                                            <div className={'z-0 pseudo-nav-bg'}/>
                                                            Creations
                                                        </button>
                                                        <button onClick={() => {
                                                            lenis.scrollTo("#services");
                                                            setOpen(false)
                                                        }} className={'text-4xl font-medium group nav-link'}>
                                                            <div className={'z-0 pseudo-nav-bg'}/>
                                                            Services
                                                        </button>
                                                        <button>
                                                            <Link to={"/learning"} className={'text-4xl font-medium group nav-link'}>
                                                                <div className={'z-0 pseudo-nav-bg'}/>
                                                                Learning
                                                            </Link>
                                                        </button>
                                                        <a onClick={() => {
                                                            logEmailAction()
                                                        }} href={"mailto:hey@appspectrum.in"}
                                                           className={'rounded-full group bg-[#4404CE] text-center text-white font-medium text-3xl py-4'}>
                                                            Hire Us
                                                        </a>
                                                    </>
                                                ) : (
                                                    <>
                                                        <button>
                                                            <Link to={"/#creations"} className={'text-4xl font-medium group nav-link'}>
                                                                <div className={'z-0 pseudo-nav-bg'}/>
                                                                Creations
                                                            </Link>
                                                        </button>
                                                        <button>
                                                            <Link to={"/#services"} className={'text-4xl font-medium group nav-link'}>
                                                                <div className={'z-0 pseudo-nav-bg'}/>
                                                                Services
                                                            </Link>
                                                        </button>
                                                        <button>
                                                            <Link to={"/learning"} className={'text-4xl font-medium group nav-link'}>
                                                                <div className={'z-0 pseudo-nav-bg'}/>
                                                                Learning
                                                            </Link>
                                                        </button>
                                                        <a onClick={() => {
                                                            logEmailAction()
                                                        }} href={"mailto:hey@appspectrum.in"}
                                                           className={'rounded-full group bg-[#4404CE] text-center text-white font-medium text-3xl py-4'}>
                                                            Hire Us
                                                        </a>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default Header;