import * as React from "react"
import Header from "./header"
import "../styles/global.css"
import ReactLenis from "@studio-freight/react-lenis";
import {useEffect, useLayoutEffect, useRef} from "react";
import {getAnalytics, logEvent, isSupported} from "firebase/analytics";
import {useLocation} from "@reach/router";
import {app} from "../firebase";
import {gsap} from "gsap";

const Layout = ({ children }) => {

    const location = useLocation()

    const cursorRef = useRef(null);
    const isTouchDevice = typeof window !== 'undefined' && 'ontouchstart' in window;


    useEffect( () => {
        // Log the event when the component mounts

        const analyticsFunc = async () => {
            const isSup = await isSupported()
            if (isSup) {
                const analytics = getAnalytics(app);
                logEvent(analytics, 'page_view', {
                    page_path: location.pathname,
                    page_title: document.title
                });
            }
        }

        analyticsFunc();

    }, [location.pathname]);

    useEffect(() => {
        const cursor = cursorRef.current;
        // If device is touchable or cursor element
        // doesn't exist, stop here
        if (isTouchDevice || !cursor) {
            return;
        }
        // Using mousemove() to animate the element cursor
        // based on the mouse cursor position (x and y)
        window.addEventListener('mousemove', (e) => {
            const { target, x, y } = e;
            // check if the mouse cursor is over some link or button
            const isTargetLinkOrBtn =
                target?.closest('a') || target?.closest('button');
            // using the GSAP power to animate some properties
            gsap.to(cursor, {
                x: x + 3,
                y: y + 3,
                duration: 0.7,
                ease: 'power4',
                opacity: isTargetLinkOrBtn ? 0.6 : 1,
                transform: `scale(${isTargetLinkOrBtn ? 3.5 : 1})`,
            });
        });
        // Using mouseleave() to animate the element cursor
        // when the mouse cursor is moved off the page
        document.addEventListener('mouseleave', () => {
            gsap.to(cursor, {
                duration: 0.7,
                opacity: 0,
            });
        });
    }, []);


    return (
        <>
            <div className={"cursor-follower"} ref={cursorRef} />
            <ReactLenis root lerp={0.2} >
                <Header/>
                <main id={"z-30"}>
                    {children}
                </main>
            </ReactLenis>
        </>
    )
}

export default Layout
